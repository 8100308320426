import { gql, useQuery } from "@apollo/client";
import { addDays, parseISO, startOfDay } from "date-fns";
import { mapValues } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { DeepPartial } from "react-hook-form";
import { useLocalStorage } from "react-use";
import { getShipmentInfo, getShipmentInfoVariables } from "../__generated__/getShipmentInfo";
import { ContentClass, Gender, LastMileServiceType } from "../__generated__/globalTypes";
import { pickupPossibleAt } from "./util";
import { v4 as uuidv4 } from "uuid";
import addHours from "date-fns/addHours";

const trim = (value: any) => typeof value === "string" ? value.trim() : value;
export interface Address {
    company: string;
    firstname: string;
    lastname: string;
    gender: Gender;

    address: string;
    zipcode: string;
    city: string;
    country: string;

    email: string;
    phoneCode: number;
    phone: string;
}
export interface Parcel {
    contentClass: ContentClass; //TODO
    species: string;
    numberOfAnimals: number;
    transportBoxId: string;

    length: number;
    height: number;
    width: number;
    weight: number;

}

export interface LastMileServiceRequest {
    pickupService: {
        type: LastMileServiceType;
        depotId: string;
    };
    deliveryService: {
        type: LastMileServiceType;
        depotId: string;
    };
}
export interface Shipment {
    id: string;
    sender: Address;
    receiver: Address;
    client: Address;

    lastMileService?: LastMileServiceRequest;

    parcels: Parcel[];
    additionalServices: string[];

    wishedTimeWindows: {
        collection: {
            from: Date;
            till: Date;
        },
        delivery: {
            from: Date;
            till: Date;
        }
    };
    notes: string;

    couponCode: string;

}

export const bookingSteps = ["animal-categorie", "sender", "receiver", ""];

const replaceNthElem = <T>(arr: T[], cb: ((e: T) => T), index: number) => arr.map((p, i) => index === i ? cb(p) : p)
const replaceLastElem = <T>(arr: T[], cb: ((e: T) => T)) => arr.length === 0 ? [cb({} as T)] : replaceNthElem(arr, cb, arr.length - 1);

const emptyShipment = {
    id: uuidv4(),
    parcels: [
        {}
    ],
    additionalServices: [],
    sender: {

    },
    receiver: {

    },
};

export const GET_SHIPMENT_INFO = gql`
  query getShipmentInfo($input: ShipmentInput!) {
    possiblePickupDates(input: $input) {
      possibleDays {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      excludedDates
      till
      from
    }
    lastMileServices(input: $input) {
      startService {
        type
        depotId
        distance
        depotAddress
      }
      endService {
        type
        depotId
        distance
        depotAddress
      }
      transportDuration
    }
  }
`;

export const useShipment = () => {
    const [shipment, setShipment] = useLocalStorage<DeepPartial<Shipment>>("current-shipment-bookingg", emptyShipment);
    const { data, loading } = useQuery<getShipmentInfo, getShipmentInfoVariables>(
        GET_SHIPMENT_INFO,
        {
            fetchPolicy: "cache-first",
            errorPolicy: "all",
            variables: {
                input: shipment as any,
            },
            skip: !shipment?.sender?.zipcode || !shipment?.receiver?.zipcode,
        }
    );
    useEffect(() => {
        try {
            (window as any).$crisp.push(["set", "user:email", [shipment.client.email]]);
        } catch { }
        try {
            (window as any).$crisp.push(["set", "user:phone", [shipment.client.phone]]);
            (window as any).$crisp.push(["set", "user:nickname", [shipment.client.firstname + " " + shipment.client.lastname]]);
        } catch { }
    }, [shipment.client])
    const regenId = () => {
        setShipment({
            ...shipment,
            id: uuidv4(),
        });
    };
    const setCouponCode = (couponCode: string) => {
        setShipment({
            ...shipment,
            couponCode
        });
    };
    const setInitialInfo = (i: { senderCountry: string, senderZip: string, receiverCountry: string, receiverZip: string, contentClass: ContentClass }) => {
        setShipment({
            ...emptyShipment,
            id: uuidv4(),
            parcels: [
                { contentClass: i.contentClass }
            ],
            sender: {
                country: i.senderCountry,
                zipcode: i.senderZip.trim(),
            },
            receiver: {
                country: i.receiverCountry,
                zipcode: i.receiverZip.trim(),
            }
        });
    };
    const setLatestParcelContentClass = (contentClass: ContentClass) => {
        setShipment({
            ...shipment,
            parcels: replaceLastElem(shipment.parcels, p => ({ ...p, contentClass })),
        });
    };
    const setLastMileService = (lastMileServiceRequest: LastMileServiceRequest) => {
        setShipment({
            ...shipment,
            lastMileService: lastMileServiceRequest,
        });
    };
    const setSender = (sender: Address) => {
        setShipment({
            ...shipment,
            sender: {
                ...shipment.sender,
                ...mapValues(sender, trim),
                email: sender?.email?.trim().length === 0 ? undefined : sender?.email?.trim()
            },
        });
    };
    const setReceiver = (receiver: Address) => {
        setShipment({
            ...shipment,
            receiver: {
                ...shipment.receiver,
                ...mapValues(receiver, trim),
                email: receiver?.email?.trim().length === 0 ? undefined : receiver?.email?.trim()
            },
        });
    };
    const setClient = (client: Partial<Address>) => {
        setShipment({
            ...shipment,
            client: {
                ...shipment.client,
                ...mapValues(client, trim),
                email: client?.email?.trim().length === 0 ? undefined : client?.email?.trim()
            },
        });
    };
    const setPickupDate = (date: Date) => {
        setShipment({
            ...shipment,
            wishedTimeWindows: {
                collection: {
                    from: addHours(startOfDay(date), 12),
                    till: addHours(startOfDay(date), 18),
                },
                delivery: {
                    from: addDays(addHours(startOfDay(date), 12), 1),
                    till: addDays(addHours(startOfDay(date), 18), 1),
                }
            }
        });
    };
    const setAdditionalServices = (additionalServices: Shipment["additionalServices"]) => {
        setShipment({
            ...shipment,
            additionalServices,
        });
    };
    const setParcel = (_parcel: Parcel, index?: number) => {
        const parcel = mapValues(_parcel, trim);
        if (typeof index === "number" && index < shipment.parcels.length) {
            setShipment({
                ...shipment,
                parcels: replaceNthElem(shipment.parcels, () => parcel, index),
            });
        } else {
            setShipment({
                ...shipment,
                parcels: [...shipment.parcels, parcel],
            });
        }
    };
    const removeParcel = (index: number) => {
        setShipment({
            ...shipment,
            parcels: shipment.parcels.filter((p, i) => i !== index),
        });

    };
    const setNotes = (notes: string) => {
        setShipment({
            ...shipment,
            notes: notes.trim(),
        });
    };
    const steps = [
        "/",
        "/booking/parcel?parcelnum=0",
        "/booking/parcel-list",
        "/booking/individual-transport",
        "/booking/pickup-date",
        "/booking/sender",
        "/booking/receiver",
        ["/booking/choose-client", "/booking/client"],
        "/booking/additional-services",
        "/booking/overview",
    ];
    const currentStep = (() => {
        if (!shipment.sender.zipcode || !shipment.receiver.zipcode) {
            return "/";
        }
        if (!shipment.parcels?.[0]?.transportBoxId || !shipment.parcels[0]) {
            return "/booking/parcel?parcelnum=0";
        }
        if (
            data?.lastMileServices.length > 0 &&
            !data?.lastMileServices.some(l => l.startService.type === LastMileServiceType.REGULAR_SERVICE && l.endService.type === LastMileServiceType.REGULAR_SERVICE && l.transportDuration <= 24) &&
            (!shipment.lastMileService?.deliveryService || !shipment.lastMileService?.pickupService)
        ) {
            return "/booking/individual-transport";
        }
        if (!loading && data && (!shipment?.wishedTimeWindows?.collection?.from || !pickupPossibleAt(parseISO(shipment.wishedTimeWindows.collection.from as any), data?.possiblePickupDates))) {
            return "/booking/pickup-date";
        }
        if (!shipment.sender.firstname) {
            return "/booking/sender";
        }
        if (!shipment.receiver.firstname) {
            return "/booking/receiver";
        }
        if (!shipment.client?.firstname) {
            return "/booking/choose-client";
        }
    })();
    const router = useRouter();
    useEffect(() => {
        const urlStepIndex = steps.findIndex(s => typeof s === "string" ? s.startsWith(router.asPath) : s.includes(router.asPath));
        const currentStepIndex = steps.findIndex(s => typeof s === "string" ? s.startsWith(currentStep) : s.includes(currentStep));

        if (currentStep && currentStepIndex < urlStepIndex) {
            router.replace(currentStep);
        }
    }, [currentStep, router.asPath]);
    //console.log("currentStep", currentStep)
    return {
        shipment: {
            ...shipment,
        },
        setLatestParcelContentClass,
        setSender,
        setReceiver,
        setClient,
        setAdditionalServices,
        setPickupDate,
        setParcel,
        removeParcel,
        setLastMileService,
        setInitialInfo,
        setNotes,
        setCouponCode,
        regenId,
        currentStep,
    }
}