/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContentClass {
  BIRDS = "BIRDS",
  FISH = "FISH",
  INSECTS = "INSECTS",
  MAMMALS = "MAMMALS",
  POULTRY = "POULTRY",
  REPTILES = "REPTILES",
}

export enum BoxSupplyType {
  OWN = "OWN",
  SOLD = "SOLD",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum LastMileServiceType {
  INDIVIDUAL_SERVICE = "INDIVIDUAL_SERVICE",
  REGULAR_SERVICE = "REGULAR_SERVICE",
  SELF_SERVICE = "SELF_SERVICE",
}

export enum OrderResultStatus {
  FINE = "FINE",
  REGEN_ID = "REGEN_ID",
}

export interface AddressInput {
  company?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  gender?: Gender | null;
  address?: string | null;
  zipcode: string;
  city?: string | null;
  country?: string | null;
  email?: any | null;
  phoneCode?: string | null;
  phone?: string | null;
}

export interface LastMileServiceRequest {
  pickupService: LastMileServiceRequestInput;
  deliveryService: LastMileServiceRequestInput;
}

export interface LastMileServiceRequestInput {
  type: LastMileServiceType;
  depotId: string;
}

export interface ParcelInput {
  contentClass?: ContentClass | null;
  species?: any | null;
  numberOfAnimals?: any | null;
  transportBoxId?: string | null;
  length?: any | null;
  height?: any | null;
  width?: any | null;
  weight?: any | null;
}

export interface RequiredAddressInput {
  company?: string | null;
  firstname: string;
  lastname: string;
  gender: Gender;
  address: string;
  zipcode: string;
  city: string;
  country: string;
  email?: any | null;
  phone: string;
}

export interface RequiredShipmentInput {
  id: string;
  sender: RequiredAddressInput;
  receiver: RequiredAddressInput;
  client: RequiredAddressInput;
  lastMileService?: LastMileServiceRequest | null;
  parcels: ParcelInput[];
  additionalServices: string[];
  pickupDate: any;
  notes?: any | null;
  couponCode?: string | null;
}

export interface ShipmentInput {
  id: string;
  sender: AddressInput;
  receiver: AddressInput;
  client?: AddressInput | null;
  lastMileService?: LastMileServiceRequest | null;
  parcels: ParcelInput[];
  additionalServices: string[];
  pickupDate?: any | null;
  notes?: any | null;
  couponCode?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
