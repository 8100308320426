import { gql } from "@apollo/client";
import {
  format,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isSaturday,
  isSunday,
  isFriday,
  isSameDay,
  parseISO,
  startOfDay,
} from "date-fns";
import * as locales from "date-fns/locale";
import { useEffect, useState } from "react";

export const useRenderClientSideOnly = () => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  return { shouldRender };
}

export const formatMoney = (value: number | undefined, locale: string) => {
  if (typeof value != "number") {
    return "";
  }

  return Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format(value / 100)
};

export const formatDate = (date: Date | string, p: { locale: string, defaultLocale: string }) => {
  p.locale = p.locale.replace("-", "")
  const cLocale = p.locale === "deDE" ? "de" : p.locale;
  const dLocale = p.defaultLocale === "deDE" ? "de" : p.locale;
  const dateLocale = locales[cLocale] ?? locales[dLocale] ?? locales["de"];
  return format(typeof date === "string" ? parseISO(date) : date, "dd.MM.yyyy", { locale: dateLocale });
}

export const GET_PRICE = gql`
  query getPrice($input: ShipmentInput!) {
    calculatePrice(input: $input) {
      total
    }
  }
`;

interface PossiblePickupDays {
  excludedDates: string[],
  till: string;
  from: string;
  possibleDays: {
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
  }
}

export const pickupPossibleAt = (day: Date, possiblePickupDays: undefined | PossiblePickupDays) => {
  const weekdayPossible =
    (isMonday(day) &&
      possiblePickupDays?.possibleDays.monday) ||
    (isTuesday(day) &&
      possiblePickupDays?.possibleDays.tuesday) ||
    (isWednesday(day) &&
      possiblePickupDays?.possibleDays.wednesday) ||
    (isThursday(day) &&
      possiblePickupDays?.possibleDays.thursday) ||
    (isFriday(day) &&
      possiblePickupDays?.possibleDays.friday) ||
    (isSaturday(day) &&
      possiblePickupDays?.possibleDays.saturday) ||
    (isSunday(day) &&
      possiblePickupDays?.possibleDays.sunday);
  if (!weekdayPossible) {
    return false;
  }

  const dayFitsTimeframe =
    possiblePickupDays &&
    day >= parseISO(possiblePickupDays.from) &&
    day <= parseISO(possiblePickupDays.till);
  if (!dayFitsTimeframe) {
    return false;
  }


  const excludedDates = (possiblePickupDays?.excludedDates ?? []).map((d) => parseISO(d))
  const isNotExcluded = !excludedDates
    .map((d) => isSameDay(d, day))
    .includes(true);
  if (!isNotExcluded) {
    return false;
  }

  return true;
};

export const parseDate = (date: string | Date) => typeof date === "string" ? parseISO(date) : date;

export const formatTimeWindow = (input: { from: Date | string, till: Date | string }): string => {
  if (startOfDay(parseDate(input.from)).getTime() === startOfDay(parseDate(input.till)).getTime()) {
      return format(parseDate(input.from), 'dd.MM.yyyy HH:mm-') + format(parseDate(input.till), 'HH:mm') + " Uhr";
  }
  return format(parseDate(input.from), 'dd.MM.yyyy HH:mm - ') + format(parseDate(input.till), 'dd.MM.yyyy HH:mm') + " Uhr";
};